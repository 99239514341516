<template>
  <div>
    <div class="vlt-footer" ref="footerParent">
      <div class="restrictor">
        <div class="container footer-root">
          <div class="row">
            <div>
              <div class="top">
                <div class="top-left">
                  <div class="tp-left">
                    <div class="tpl-logo footertext">
                      <img :src="vendorLogo" alt="logo" loading="lazy" />
                    </div>
                    <div class="footertext" @click="openAboutPopup(true)">
                      ABOUT
                    </div>
                    <div class="footertext" @click="toggleHelpPopup(true)">
                      HELP
                    </div>
                    <!-- <div class="footertext">
                  <a :href="help" target="_blank">HELP</a>
                </div>-->

                    <!-- <div class="footertext" @click="toggleTermsPopup(true)">TERMS OF USE</div> -->
                    <div >
                      <a  class="footertext" :href="terms" target="_blank">TERMS OF USE</a>
                    </div>

                    <!-- <div class="footertext" @click="togglePrivacyPopup(true)">PRIVACY POLICY</div> -->
                    <div >
                      <a class="footertext" :href="privacyPolicy" target="_blank"
                        >PRIVACY POLICY</a
                      >
                    </div>
                    <div class="footertext" v-if="activeContact" @click="openContactusPopup(true)">
                      CONTACT US
                    </div>
                  </div>
                </div>
                <div class="top-right footer-share-icons">
                  <div class="tp-right">
                    <ul>
                      <li>
                        <a
                          :href="androidLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="play store"
                        >
                          <img
                            src="@/assets/icons/googleplay.svg"
                            alt="gp-grey-ico"
                            class="tp-center-ico"
                            loading="lazy"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          :href="itunesLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="app store"
                        >
                          <img
                            src="@/assets/icons/app-store.svg"
                            alt="gp-grey-ico"
                            class="tp-center-ico"
                            loading="lazy"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div
                  class="bottom-row-child"
                >
                  <div class="bt-left">
                    <p>&copy;{{ date }} {{ appConfig.appName }}</p>
                    <p>
                      <a
                        class="website"
                        href="https://mobiotics.com/"
                        target="_blank"
                      >
                        Powered by Mobiotics</a
                      >
                    </p>
                  </div>
                </div>
                <div
                  class="bottom-row-child"
                >
                  <div class="bt-right">
                    <ul>
                      <li>
                        <p>Connect with us</p>
                      </li>
                      <li>
                        <a
                          :href="facebookLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="facebook link"
                        >
                          <img
                            src="@/assets/icons/facebook-logo.svg"
                            alt="facebook logo"
                            loading="lazy"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          :href="pinterestLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="pinterest link"
                        >
                          <img
                            src="@/assets/icons/pinterest-logo.svg"
                            alt="pinterest logo"
                            loading="lazy"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          :href="twitterLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="twitter link"
                        >
                          <img
                            src="@/assets/icons/twitter-logo.svg"
                            alt="twitter logo"
                            loading="lazy"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          :href="instagramLink"
                          target="_blank"
                          rel="noopener"
                          aria-label="instagram link"
                        >
                          <img
                            src="@/assets/icons/instagram-logo.svg"
                            alt="instagram logo"
                            loading="lazy"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="popup-animate">
      <popup-help
        v-if="showHelpPopup"
        :closePopupCB="() => toggleHelpPopup(false)"
      />
      <popup-about
        v-if="showAboutPopup"
        :closePopupCB="() => openAboutPopup(false)"
      />
       <popup-contactus
        v-if="showContactusPopup"
        :closePopupCB="() => openContactusPopup(false)"
      />
      <popup-TandC
        v-if="showTandCPopup"
        :closePopupCB="() => toggleTermsPopup(false)"
      />
      <popup-privacy
        v-if="showPrivacyPopup"
        :closePopupCB="() => togglePrivacyPopup(false)"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let footer_observer=null;
export default {
  data() {
    return {
      showHelpPopup: false,
      showAboutPopup: false,
      showContactusPopup:false,
      activeContact:false,
      showTandCPopup: false,
      showPrivacyPopup: false,
      date: new Date().getFullYear(),
      facebookLink: "",
      twitterLink: "",
      pinterestLink: "",
      instagramLink: "",
      androidLink: "",
      itunesLink: "",
      privacyPolicy: "",
      terms: "",
      help: "",
      vendorLogo: "",
    };
  },
  components: {
    "popup-help": () =>
      import(/* webpackChunkName: "popupHelp" */ "../Popups/help.vue"),
    "popup-about": () =>
      import(/* webpackChunkName: "popupAbout" */ "../Popups/about.vue"),
    "popup-contactus": () =>
      import(/* webpackChunkName: "popupAbout" */ "../Popups/contactus.vue"),  
    "popup-TandC": () =>
      import(/* webpackChunkName: "popupTandC" */ "../Popups/tandC.vue"),
    "popup-privacy": () =>
      import(/* webpackChunkName: "popupPrivacy" */ "../Popups/privacy.vue"),
  },
  computed: {
    ...mapGetters(["appConfig", "vendorAssets"]),
  },
  created() {
    this.vendorLogo = require(`@/assets/icons/logo/${this.vendorAssets}/logo.svg`);
    if(
      process.env.VUE_APP_ENV==="production" &&
      this.appConfig &&
      this.appConfig.vendorDetails &&
      this.appConfig.vendorDetails &&
      this.appConfig.vendorDetails.contactInfo
    ){
      this.activeContact=true;
    }
  },
  mounted() {
    this.basicDisplays();
    footer_observer=new ResizeObserver((mutation)=>{
      let height=mutation[0] && mutation[0].target && mutation[0].target.offsetHeight;
      height=height?height:200;
      document.documentElement.style.setProperty('--footer-height',`${height}px`);
    });
    footer_observer.observe(this.$refs.footerParent);
  },
  beforeDestroy(){
    footer_observer.disconnect();
  },
  methods: {
    toggleHelpPopup(state) {
      this.showHelpPopup = state;
    },
    openAboutPopup(state) {
      this.showAboutPopup = state;
    },
    openContactusPopup(state) {
      this.showContactusPopup = state;
    },
    toggleTermsPopup(state) {
      this.showTandCPopup = state;
    },
    togglePrivacyPopup(state) {
      this.showPrivacyPopup = state;
    },
    basicDisplays() {
      let vendorDetails = this.appConfig.vendorDetails;
      if (vendorDetails) {
        this.androidLink = this.appConfig.vendorDetails.playstoreUrl || "";
        this.itunesLink = this.appConfig.vendorDetails.appstoreUrl || "";
        this.privacyPolicy = this.appConfig.vendorDetails.ppUrl || "#";
        this.terms = this.appConfig.vendorDetails.tncUrl || "#";
        this.help = this.appConfig.vendorDetails.faqUrl || "#";

        if (vendorDetails.social) {
          this.facebookLink =
            this.appConfig.vendorDetails.social.facebook || "";
          this.instagramLink =
            this.appConfig.vendorDetails.social.instagram || "";
          this.twitterLink = this.appConfig.vendorDetails.social.twitter || "";
          this.pinterestLink =
            this.appConfig.vendorDetails.social.pinterest || "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.row {
  margin: 0;
}
.container {
  margin-top: 0 !important;
}
.vlt-footer {
  background: $clr-bg-black 0% 0% no-repeat padding-box;
  padding: 10px 20px 15px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  .restrictor {
    max-width: var(--max-width);
    margin: auto;
    .footer-root {
      width: var(--max-width);
    }
  }
}
.top {
  padding: 5px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid $clr-light-hlt-1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .top-right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }
  .tpl-logo {
    img {
      display: block;
      width: 70px;
    }
  }
  ul {
    li {
      display: inline-block;
      padding: 8px 0;
      a,
      span {
        font-size: $text-xs;
        display: block;
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }
}
.footer-text-cont {
  display: inline-flex;
  .ft-links {
    margin-right: 15px;
  }
}
.tp-right ul {
  text-align: right;
  img {
    height: 25px;
  }
}
.tp-center-ico {
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }
}
.website {
  // text-decoration: underline;
  font-weight: $font-weight-regular;

  &:hover {
    color: $font-clr-gray;
  }
}
.footertext {
  display:block;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 0.9rem;
  color: darken($font-clr-gray, 40%);
  cursor: pointer;
  padding-top: 10px;
  float: left;
  margin-right: 15px;
  &:hover {
    color: $font-clr-gray;
  }
  a {
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 0.875rem;
    color: darken($font-clr-gray, 40%);
    &:hover {
      color: $font-clr-gray;
    }
  }
}
.bottom {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  min-height:30px;
  p{
    font-size: 0.9rem;
  }
  .bottom-row-child {
    padding: 0px;
    font-size: 0.875rem;
  }
  p {
    // font-size: $text-xs;
    font-weight: 300;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    color: $font-clr-gray-1;
  }
  .bt-left {
    display: flex;
    flex-flow: row nowrap;
    p {
      margin-right: 10px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      color: $font-clr-gray-1;
      font-size: 0.9rem;
    }
  }
  .bt-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    ul {
      text-align: right;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-evenly;
      li {
        display: block;

        a {
          display: block;
          text-align: center;
          border-radius: 40px;
          line-height: 34px;
          margin-left: 12px;
          opacity: 0.6;
          transition: all 0.3s ease;
          &:hover {
            opacity: 1;
          }
          img {
            width: 22px;
            display:block;
          }
        }
      }
    }
  }
}
.onlyMobile {
  display: none;
}

@include breakpoint(max600) {
  .vlt-footer {
    padding: 0px;
  }
  .tpl-logo {
    width: 100%;
  }
  .tp-right ul {
    li {
      padding: 0;
    }
  }
  .footer-share-icons {
    margin-top: 20px;
  }
  .footertext {
    float: none;
  }
  .footer-text-cont .ft-links {
    margin-right: 6px;
  }
  .hideInMobile {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
}
//(max-width:480px)

@include breakpoint(max480){
  .bottom{
    padding:10px 0px;
    .bt-left {
      height:50px;
      flex-flow:column nowrap;
      justify-content: space-between;
    }
    .bt-right ul{
      height:50px;
      display:grid;
      grid-template-columns: repeat(4,minmax(0,1fr));
      align-content: space-between;
      
      li:first-of-type{
        grid-column: 1/5;
        text-align: left;
      }
      li a{
        margin-left:0;
        margin-right:12px;
      }
    }
  }

}

@include breakpoint(min600max768) {
  .footer-share-icons {
    float: right;
  }
  .footertext {
    float: none;
  }
  .hideInMobile {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
  .tpl-logo {
    width: 100%;
  }
  .tp-right ul {
    li {
      padding: 0;
    }
  }
}

@include breakpoint(max768) {
  .top-right ul li {
    display: block;
    margin-top: 8px;
  }
}

@include breakpoint(min768max980) {
  .tpl-logo{
    width:100% !important;
  }
  .tp-left {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-top:10px;
  }
  .hideInMobile {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
  .tpl-logo {
    width: 100%;
  }
  .tp-right ul {
    li {
      padding: 0;
    }
  }
}

@include breakpoint(min980) {
  .footertext {
    padding-top: 0;
  }
  .hideInMobile {
    display: block;
  }
  .tp-left {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-top:10px;
  }
}
</style>